import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Site from  "../site/home"
import Blog from  "../blog/home"



const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query TypeQuery {
    site {
      siteMetadata {
        type
      }
    }
  }
  `)

if(data.site.siteMetadata.type === 'site'){
  return (<Site />);
}else{
  return (<Blog />);
}
}

export default IndexPage;
