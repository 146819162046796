import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import Seo from "../components/seo"
import ButtonDownload from  "../components/buttonDownload"

const IndexPage = () => (
  <Layout>
    <Seo title="Conetist l'application pour les infirmiers libéraux" />
    <div className='line blue-start prez'>
      <img src="/images/background.svg" className="background" alt='background logo' />
      <Image src="captures.png" className='image' width="400" />    
      <p className='text'>
        L’application mobile qui donne du temps aux infirmiers libéraux  
      </p>
      <ButtonDownload />
    </div>
    <div className='line map'>
      <img src="/images/background.svg" className="background" alt='background logo' />
      <p className='text'>Naviguez vers vos patients en toute sérenité</p>
      <Image src="map.png" className='image' width="400" />    
      <ButtonDownload />
      <Image src="capture-map.png" className='image second'  />    
      <p className='text second'>Visualisez sur le plan votre patient depuis sa fiche</p>
    </div>
    <div className='line blue-middle share'>
      <Image src="image-share.png" className='image'  />    
      <p className='text'>
      Partagez votre patientèle avec vos collègues en quelques clics</p>
      <ButtonDownload />
    </div>
    <div className='line blue-middle end historic'>
      <img src="/images/background.svg" className="background end" alt='background logo' />
      <Image src="image-historic.png" className='image' />    
      <p className='text'>
      Suivez l’évolution de votre cabinet avec l’historique</p>
      <ButtonDownload />
    </div>
  </Layout>
)

export default IndexPage;
