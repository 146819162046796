import React, {Component} from "react"
import { useStaticQuery, graphql, Link,navigate } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { render } from "react-dom"
import ButtonDownload from  "../components/buttonDownload"
import WriteBlog from  "../components/cards/writeblog"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr"),
            author{
              name
            }
            title
            description
            image {
              publicURL
            }
          }
        }
      }
    }
  }
  `)

  // const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
  <Layout>
    <SEO title="Blog Conetist pour les infirmiers libéraux" />
    <div className='line blue-start blog'>
      <h1>
        Blog de Conetist pour les infirmiers libéraux
      </h1>
      <img src="/images/background.svg" className="background" />
      <WriteBlog className="fix-height fix-width" />
    {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug} className="card fix-height fix-width" onClick={()=> navigate(node.fields.slug) }>
            <div className="back" style={{
                gridRowEnd: 5
            }}></div>
            <header className="header">
              <h2
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h2>
            </header>
            <section className="section justify">
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
            {node.frontmatter.image != undefined &&
                <img
                  src={node.frontmatter.image.publicURL}
                  alt={node.frontmatter.title}
                />
            }
            <footer className="footer">
              <small className="author">écrit par {node.frontmatter.author.name}</small>
              <small className="date">{node.frontmatter.date}</small>
            </footer>
          </article>
        )
      })}
    </div>
  </Layout>
)}

export default IndexPage;
