import React from "react"
import { useStaticQuery, graphql } from "gatsby"

  const WriteBlog = (props) =>{ 
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "blog-post.svg"}){
        publicURL
      }
    }
    `);

    var className = props.className != undefined ? "card " + props.className : "card";

    return(
        <div className={className}>
        <div className="back" style={{
            gridRowEnd: 4
        }}></div>
        <div className="header">
            <h2>Ecrivez votre propre blog post</h2>
        </div>
        <div className="section justify">
            <p>Si vous souhaitez être publié sur ce blog, envoyez nous vos blog posts à cette adresse :
                <br /> contact@conetist.com</p>
        </div>
            <img
            src={data.file.publicURL}
            alt='Ecrivez votre propre blog post'
            />
        </div>
        )      
  }

  export default WriteBlog;
  